// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;
// import './js/navigation.js'; 
// import Foundation from 'foundation-sites';

// import { Foundation } from './theme'; // currently not using



// var WebFont = require('webfontloader'); // Page base
// import { Foundation } from 'foundation-sites/js/foundation.core';
// import * as CoreUtils from 'foundation-sites/js/foundation.core.utils'; // not using - needed for utils ??????
// import { Box } from 'foundation-sites/js/foundation.util.box'; // Will return an object that contains the dimensions of element
import {
  onImagesLoaded
} from 'foundation-sites/js/foundation.util.imageLoader'; // This will execute your callback function after all the images in your jQuery collection have loaded.
// import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard'; // Methods for keyboard interaction 
import {
  MediaQuery
} from 'foundation-sites/js/foundation.util.mediaQuery'; // The media query library used by Foundation has two publicly accessible functions and two properties
// import { Motion, Move } from 'foundation-sites/js/foundation.util.motion'; // Two utils
// import { Nest } from 'foundation-sites/js/foundation.util.nest'; ??????
// import { Timer } from 'foundation-sites/js/foundation.util.timer'; // Similar to setInterval, except you can pause and resume where you left off
// import { Touch } from 'foundation-sites/js/foundation.util.touch'; // Allows you to add swipe* and pseudo-drag events to elements
// import { Triggers } from 'foundation-sites/js/foundation.util.triggers'; // Provides a number of event listeners and triggers your script can hook into
// import { Abide } from 'foundation-sites/js/foundation.abide';
// import { Accordion } from 'foundation-sites/js/foundation.accordion';
// import { AccordionMenu } from 'foundation-sites/js/foundation.accordionMenu'; // Page base
// import { Drilldown } from 'foundation-sites/js/foundation.drilldown'; // Page base
// import { Dropdown } from 'foundation-sites/js/foundation.dropdown';
// import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu'; // Page base
import {
  Equalizer
} from 'foundation-sites/js/foundation.equalizer';
// import { Interchange } from 'foundation-sites/js/foundation.interchange';
// import { Magellan } from 'foundation-sites/js/foundation.magellan';
// import { OffCanvas } from 'foundation-sites/js/foundation.offcanvas'; // Page base
// import { Orbit } from 'foundation-sites/js/foundation.orbit';
// import { ResponsiveMenu } from 'foundation-sites/js/foundation.responsiveMenu'; // Page base
// import { ResponsiveToggle } from 'foundation-sites/js/foundation.responsiveToggle'; // _jwp_nav-title-bar.php
// import { Reveal } from 'foundation-sites/js/foundation.reveal';
// import { Slider } from 'foundation-sites/js/foundation.slider';
// import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll';
// import { Sticky } from 'foundation-sites/js/foundation.sticky';
// import { Tabs } from 'foundation-sites/js/foundation.tabs';
// import { Toggler } from 'foundation-sites/js/foundation.toggler';
// import { Tooltip } from 'foundation-sites/js/foundation.tooltip';
// import { ResponsiveAccordionTabs } from 'foundation-sites/js/foundation.responsiveAccordionTabs';

// Foundation.addToJquery($);

// Add Foundation Utils to Foundation global namespace for backwards
// compatibility.

// *** Not using - needed for utils ??????
// Foundation.rtl = CoreUtils.rtl;
// Foundation.GetYoDigits = CoreUtils.GetYoDigits;
// Foundation.transitionend = CoreUtils.transitionend;
// Foundation.RegExpEscape = CoreUtils.RegExpEscape;
// Foundation.onLoad = CoreUtils.onLoad;

// *** Not using - needed for utils ??????
// Foundation.Box = Box;
Foundation.onImagesLoaded = onImagesLoaded;
// Foundation.Keyboard = Keyboard;
Foundation.MediaQuery = MediaQuery;
// Foundation.Motion = Motion;
// Foundation.Move = Move;
// Foundation.Nest = Nest;
// Foundation.Timer = Timer;

// Touch and Triggers previously were almost purely sede effect driven, 
// so no need to add it to Foundation, just init them.

// *** Not using - needed for utils ??????
// Touch.init($);
// Triggers.init($, Foundation);
MediaQuery._init();

// Foundation.plugin(Abide, 'Abide');
// Foundation.plugin(Accordion, 'Accordion');
// Foundation.plugin(AccordionMenu, 'AccordionMenu'); // Page base
// Foundation.plugin(Drilldown, 'Drilldown'); // Page base
// Foundation.plugin(Dropdown, 'Dropdown');
// Foundation.plugin(DropdownMenu, 'DropdownMenu'); // Page base
Foundation.plugin(Equalizer, 'Equalizer');
// Foundation.plugin(Interchange, 'Interchange');
// Foundation.plugin(Magellan, 'Magellan');
// Foundation.plugin(OffCanvas, 'OffCanvas'); // Page base
// Foundation.plugin(Orbit, 'Orbit');
// Foundation.plugin(ResponsiveMenu, 'ResponsiveMenu'); // Page base
// Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle');
// Foundation.plugin(Reveal, 'Reveal');
// Foundation.plugin(Slider, 'Slider');
// Foundation.plugin(SmoothScroll, 'SmoothScroll');
// Foundation.plugin(Sticky, 'Sticky');
// Foundation.plugin(Tabs, 'Tabs');
// Foundation.plugin(Toggler, 'Toggler');
// Foundation.plugin(Tooltip, 'Tooltip');
// Foundation.plugin(ResponsiveAccordionTabs, 'ResponsiveAccordionTabs');

// export { Foundation };


// import {
//     initializer
// } from './js/foundation';
// require('waypoints/lib/noframework.waypoints.min');
// import 'slick-carousel/slick/slick.min.js';
// import 'slick-carousel/slick/slick.css';
// import './styles/critical.scss';
import './page-home.scss';



// import { 
//     SlickSlider
// } from './js/js-parts/js-slick-slider.js';

// import {
//   globeDatacaps
// } from './js/js-parts/js-globe-data-caps.js';


// import {
//   TrackballControls
// } from './js/js-parts/js-trackball-controls';
// import { OrbitControls } from 'https://unpkg.com/browse/three@0.150.1/examples/jsm/controls/OrbitControls.js';
// initializer.key_initFoundation();
// jQuery(document).foundation();
// cannot apply update. Need to do a full reload - only accept if full relod is not needed
// if(module && module.hot){
//     module.hot.accept();
// }    
// ****************************** // 
// ****** WRAPPER  ****** //  
// ****************************** // 
// IIFE - Immediately Invoked Function Expression
(function (bptcode) {

  // The global jQuery object is passed as a parameter 
  bptcode(window.jQuery, window, document);

}(function ($, window, document) {

  // The $ is now locally scoped

  $(document).ready(function () {

    // *** CODE HERE *** //
    $(document).foundation();
    setTimeout(function () {
      Foundation.reInit('equalizer');
    }, 250);


    // const globeDataCaps = globeDatacaps();
    const world = Globe({
      'animateIn': false
    });
    const $svg = $('.hero-content-cta__svg');


    if (document.getElementById('globe')) {

      const N = 20;
      const N_PATHS = 10;
      const MAX_POINTS_PER_LINE = 10000;
      const MAX_STEP_DEG = 1;
      const MAX_STEP_ALT = 0.015;
      const gData = [...Array(N_PATHS).keys()].map(() => {
        let lat = (Math.random() - 0.5) * 90;
        let lng = (Math.random() - 0.5) * 360;
        let alt = 0;

        return [
          [lat, lng, alt], ...[...Array(Math.round(Math.random() * MAX_POINTS_PER_LINE)).keys()].map(() => {
            lat += (Math.random() * 2 - 1) * MAX_STEP_DEG;
            lng += (Math.random() * 2 - 1) * MAX_STEP_DEG;
            alt += (Math.random() * 2 - 1) * MAX_STEP_ALT;
            alt = Math.max(0, alt);

            return [lat, lng, alt];
          })
        ];
      });

      const gData2 = [...Array(N).keys()].map(() => ({
        lat: (Math.random() - 0.5) * 180,
        lng: (Math.random() - 0.5) * 360,
        alt: Math.random() * (0.3 - 0.2) + 0.2,
        radius: 1,
        color: ['#00A5E4', '#00A5E4', '#00A5E4', '#00A5E4'][Math.round(Math.random() * 3)]
      }));

      world(document.getElementById('globe'))
        .globeImageUrl('/wp-content/uploads/2023/03/image-world-jpeg-1-scaled.jpg')
        // .globeImageUrl('/wp-content/uploads/2023/04/image-earth-grey-1.png')
        .backgroundColor('#FFFFFF')
        .showGlobe(true)

        .customLayerData(gData2)
        .customThreeObject(d => new THREE.Mesh(
          new THREE.BoxGeometry( 1, 1, 1 ),
          new THREE.MeshLambertMaterial({ color: d.color })
        ))
        .customThreeObjectUpdate((obj, d) => {
          Object.assign(obj.position, world.getCoords(d.lat, d.lng, d.alt));
        });
      world.animateIn = 0;
      world.controls().autoRotate = true;
      world.controls().autoRotateSpeed = 1.0;
      world.controls().enabled = false;
      world.controls().update;

      (function moveSpheres() {
        gData2.forEach(d => d.lat += 0.2);
        world.customLayerData(world.customLayerData());
        requestAnimationFrame(moveSpheres);
      })();
    }



    setTimeout(() => {
      $svg.addClass('is-active');
    }, 2250);

    $(window).on('resize scroll', function () {
      world.controls().autoRotateSpeed = 1.0;
    });

    const mobile = window.matchMedia('(max-width: 639px)');
    const tablet = window.matchMedia('(min-width: 640px)');
    const desktop = window.matchMedia('(min-width: 1024px)');

    function handleMobileChange(e) {
      if (e.matches) {
        world.width(540)
          .height(540)
      }
    }
    mobile.addListener(handleMobileChange);
    handleMobileChange(mobile);

    function handleTabletChange(e) {
      if (e.matches) {
        world.width(1250)
          .height(1250)
      }
    }
    tablet.addListener(handleTabletChange);
    handleTabletChange(tablet);

    function handleDesktopChange(e) {
      if (e.matches) {
        world.width(1550)
          .height(1550)
      }
    }
    desktop.addListener(handleTabletChange);
    handleDesktopChange(desktop);



    // *** END CODE *** //
    // ****************************** // 
    // ****** END WRAPPER  ****** // 
    // ****************************** //
  });
}));